import React from 'react';

const Products = () => {
    return (
        <div>
            <h1>Products Page</h1>
            {/* Add your content here */}
        </div>
    );
};

export default Products;